<template>
	<div>
		<slot />
	</div>
</template>

<style lang="scss" scoped>
div {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	gap: 25px;
	padding-top: 10px;

	:deep(a) {
		margin: 0;
	}
}
</style>
